.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.wheel {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 2px solid #000;
  overflow: hidden;
  animation: spin 30s linear infinite;
}

.wheel-item {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform-origin: 0% 0%;
  transform: rotate(calc(360deg / var(--total-items) * var(--item-index)));
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3600deg);
  }
}